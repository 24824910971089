import { Inject, Injectable } from '@angular/core';
import { BaseApi } from '@ministrary/shared/apis/base.api';
import { eRoles } from '@ministrary/shared/enums/roles.enum';
import { iChurch } from '@ministrary/shared/interfaces/church.interface';
import { iEnvironment } from '@ministrary/shared/interfaces/environment.interface';
import { UsersChurch } from '@ministrary/shared/interfaces/users-church.interface';

@Injectable({
  providedIn: 'root'
})
export class ChurchApi extends BaseApi<iChurch> {
  constructor(@Inject('environment') environment: iEnvironment) {
    super('church', environment.apiUrl);
  }

  async loadAllChurches() {
    const userRoles = this.userRoles || [];
    if (userRoles.includes(eRoles.SUPER_ADMIN)) {
      const { data } = await this.select('*, address(*)');
      return data as unknown as iChurch[];
    }

    const { data } = await this.supabase.from('users_church').select('church(*, address(*))').match({ user_id: this.userId, active: true });
    return (data as unknown as UsersChurch[])?.map(userChurch => userChurch.church) as iChurch[];
  }
}
