import { Inject, Injectable } from '@angular/core';
import { BaseApi } from '@ministrary/shared/apis/base.api';
import { iEnvironment } from '@ministrary/shared/interfaces/environment.interface';
import { iPeopleSearch } from '@ministrary/shared/interfaces/people-search.interface';
import { iUser } from '@ministrary/shared/interfaces/user.interface';
import { UsersChurch } from '@ministrary/shared/interfaces/users-church.interface';

@Injectable({
  providedIn: 'root'
})
export class UsersChurchApi extends BaseApi<UsersChurch> {
  constructor(@Inject('environment') environment: iEnvironment) {
    super('users_church', environment.apiUrl);
  }

  getAllByUser(userId: string) {
    return this.supabase.from('users_church').select('*').eq('user_id', userId).returns<UsersChurch[]>();
  }

  getByUser(userId: string) {
    return this.supabase.from('users_church').select().eq('user_id', userId).single();
  }

  async loadUsersByChurch(churchId?: string, search?: iPeopleSearch) {
    const { full_name, phone, status, roles, pageSize, pageIndex } = search || {};

    let query = this.supabase.from('users_church').select('users!users_church_user_id_fkey!inner(*)', { count: 'exact' }).match({ church_id: churchId, active: true });

    if (full_name) query = query.or(`full_name.ilike.%${full_name}%,email.ilike.%${full_name}%`, { referencedTable: 'users' });
    if (phone) query = query.ilike('users.phone', `%${phone}%`);
    if (status != undefined) query = query.eq('users.active', status);
    if (roles) query = query.contains('users.roles', [roles]);
    if (pageIndex && pageSize) query = query.range((pageIndex - 1) * pageSize, pageIndex * pageSize - 1);

    const { data, count } = await query.order('created_at');
    const users = (data as unknown as UsersChurch[])?.map(x => x.users) as unknown as iUser[];
    return { users, count: count || 0 };
  }

  delete(userId: string, churchId: string) {
    return this.supabase.from('users_church').delete().eq('user_id', userId).eq('church_id', churchId);
  }
}
